import React, {FC} from "react";
import Link from "next/link";
import {IIndicatorButton as IProps} from "./types";
import cls from "../header.module.scss"

const IndicatorButton: FC<IProps> = ({
                                         url,
                                         handleButtonClick,
                                         title,
                                         titleSpan
                                     }) => {

    return (
        <>
            {(url && (
                <Link
                    href={url}
                    passHref
                >
                    {/*@ts-ignore*/}
                    <a
                        aria-label={url}
                        className={cls.indicator__button}
                        onClick={handleButtonClick}
                    >
                        {title}
                    </a>
                </Link>
            )) || (
                <button
                    aria-label="indicatorButton"
                    type="button"
                    className={cls.indicator__button}
                    onClick={handleButtonClick}
                >
                    {title}
                </button>
            )}
            <span
                onClick={handleButtonClick}
                className={`${cls["indicator-title-fms"]}`}
            >
                    {titleSpan}
                </span>
        </>
    )
}

export default IndicatorButton;
