import React, {Fragment, useState} from "react";
import Link from "next/link";
import dynamic from 'next/dynamic'
import {LogoSmallSvg} from "../../../svg";
import IndicatorPanel from "./IndicatorPanel";
import Search from "../Search/Search"
import cls from "../header.module.scss"
import Image from "next/image";
import {domainUrl} from "../../../helper";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";

const CartIndicator = dynamic(() => import("../Indicator/IndicatorCart"))
const Departments = dynamic(() => import('../Departments/Departments'))
const IndicatorSearch = dynamic(() => import("../Indicator/IndicatorSearch"))

const NavPanelChild = ({ layout, dbName }: { layout: string; dbName: string }) => {
    let logo: JSX.Element | null = null;
    const dbNameCopy = useSelector((state: IState) => state.general.dbName)
    const DBName = dbName ?? dbNameCopy
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    let departments: JSX.Element | null = null;
    let searchIndicator: JSX.Element | null = null;
    const [imgSrc, setImgSrc] = useState(`${DBName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`)

    const loadDefaultLogo = () => {
        setImgSrc(`${DBName}/stores-logo.svg?v=${cacheVersion}`)
    }

    switch (layout) {
        case "compact":
            logo = (
                <div className={cls["nav-panel__logo"]}>
                    <Link href="/">
                        <a>
                            <LogoSmallSvg/>
                        </a>
                    </Link>
                </div>
            )
            searchIndicator = (
                <div className={cls["nav-panel__item_row"]}>
                    <IndicatorSearch/>
                </div>
            )
            break;
        case "default":
            departments = (
                <div className={cls["nav-panel__departments"]}>
                    <Departments />
                </div>
            );
    }
    return (
        <Fragment>
            {logo}
            {/*{departments}*/}
            <div className={cls.header__logo}>
                <Link href="/">
                    <a>
                        <Image
                            src={domainUrl(imgSrc)}
                            alt="logo"
                            placeholder="blur"
                            layout="fill"
                            blurDataURL={`/zega/blur1.webp`}
                            objectFit='contain'
                            objectPosition="left"
                            onError={loadDefaultLogo}
                        />
                    </a>
                </Link>
            </div>
            <div className={`${cls["site-header__search"]}`}>
                <Search context="header"/>
            </div>
            <div className={`${cls["nav-panel__indicators"]}`}>
                <IndicatorPanel
                    searchIndicator={searchIndicator}
                />
                {/*<div className={`${cls["nav-panel__item_row"]}`}>*/}
                {/*    <CartIndicator/>*/}
                {/*</div>*/}
            </div>
        </Fragment>
    )
}
export default NavPanelChild;
