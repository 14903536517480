import React, {FC, useEffect, useRef, useState, memo, ReactNode} from "react";
import Link from "next/link";
import {IIndicatorComponentProps as IProps} from "../Indicator/types"
import {handleClassname} from "./helpers";
import IndicatorButton from "../Indicator/IndicatorButton";
import cls from "../header.module.scss"

export const Indicator: FC<IProps> = (props) => {
    const [opens, setOpen] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const {
        onClick,
        dropdown,
        url,
        className,
        icon,
        openEd,
        func,
        onOpen,
        onClose,
        value,
    } = props;
    const totalValue = <span className={cls.indicator__value}>{value && value as ReactNode}</span>

    useEffect(() => {
        const handleOutsideClick = (event: { target: Node }) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !opens) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleOutsideClick as (event: { target: EventTarget | null }) => void);

        if (openEd && wrapperRef.current && opens) {
            setOpen(false);
            if (func) {
                func(false);
            }
        }

        if (opens && onOpen) {
            onOpen();
        }

        if (!opens && onClose) {
            onClose();
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick as (event: {
                target: EventTarget | null
            }) => void);
        }
    }, [openEd])

    const setWrapperRef = (node: HTMLDivElement) => {
        wrapperRef.current = node;
    };

    const title = (
        <span className={cls.indicator__area} onClick={handleButtonClick}>
            {icon}
            {totalValue?.props?.children > 0 ? totalValue : null}
        </span>
    );

    function handleButtonClick(event: { preventDefault: () => void }) {
        if (onClick) {
            onClick(event as MouseEvent);
        }
        if (dropdown) {
            event.preventDefault();
        }

        setOpen(!opens);
    }


    return (
        <div className={handleClassname(className, opens)} ref={setWrapperRef}>
            <IndicatorButton
                titleSpan={props.title}
                title={title}
                url={url}
                handleButtonClick={handleButtonClick}
            />
            {dropdown && (
                <div className={cls.indicator__dropdown}>
                    <div className={handleClassname(className, opens)}>{dropdown}</div>
                </div>
            )}
        </div>
    );
}

export default memo(Indicator);
