import React from "react";
import NavPanelChild from "../NavPanel/index";
import cls from "../header.module.scss"

function NavPanel({layout = "default", dbName}: any) {

    return (
        <div className={`${cls["nav-panel"]}`}>
            <div className={`${cls["nav-panel__container"]}`}>
                <div className={`${cls["nav-panel__row"]}`}>
                    <NavPanelChild
                        layout={layout}
                        dbName={dbName}
                    />
                </div>
            </div>
        </div>
    );
}


export default NavPanel;
