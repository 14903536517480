import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import cls from "../header.module.scss"
//SVG
import {SignedUserSvg, UserSvg} from "../../../svg";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../../types/state";

const Indicator = dynamic(() => import("./index"))
const IndicatoDropDownBody = dynamic(() => import("./IndicatoDropDownBody"))

const IndicatorAccount = () => {
    const authenticated = useSelector((state: IState) => state.customer.authenticated);
    const customer = useSelector((state: IState) => state.customer);
    const [open, setOpen] = useState<boolean>(false);


    const func = () => {
        setOpen((open) => !open);
    };

    const dropdown = () => <IndicatoDropDownBody isOpened={open} setOpen={setOpen} func={func}/>
    const iconSvg = () => <div>{customer.authenticated ? <SignedUserSvg/> : <UserSvg/>}</div>

    return (
        <>
            <Indicator
                url="/account/profile"
                className={`${cls["account-logo-svg"]}`}
                func={func}
                openEd={open}
                account={"account"}
                dropdown={!authenticated ? dropdown() : ""}
                icon={iconSvg()}
                title={
                    !customer.authenticated ? (
                        <FormattedMessage id="sign_in" defaultMessage="Sign in"/>
                    ) : (
                        <FormattedMessage id="my_account" defaultMessage="My account"/>
                    )
                }
            />
        </>

    );
}

export default IndicatorAccount;


// // react
// import React, {useState} from "react";
//
// // third-party
// import {useRouter} from "next/router";
// import {FormattedMessage} from "react-intl";
//
// //SVG
// import {FailSvg, PersonFill} from "../../svg";
// // application
// import {useDispatch, useSelector} from "react-redux";
//
// import {apiUrlWithStore, url} from "../../helper";
//
// import {cartAddItemAfterLogin, cartRemoveItemAfterLogin} from "../../store/cart";
// import dynamic from 'next/dynamic'
// import {useToast} from "../../atoms/Toast/useToast";
// import {IState} from "../../types/state";
//
// const Index = dynamic(() => import("./Index"))
// const IndicatoDropDownBody = dynamic(() => import("./IndicatoDropDownBody"))
//
// const IndicatorAccount = () => {
//     const history = useRouter();
//     const dispatch = useDispatch();
//     const toast = useToast();
//     const authenticated = useSelector((state: IState) => state.customer.authenticated);
//     const customer = useSelector((state: IState) => state.customer);
//     const cartToken = useSelector((state: IState) => state.cartToken);
//     const cart = useSelector((state: IState) => state.cart);
//     const selectedData = useSelector((state: IState) => state.locale.code);
//
//     const [email, SetEmail] = useState<string>();
//     const [pass, SetPass] = useState<string>();
//     const [open, setOpen] = useState<boolean>(false);
//
//     const handlerChange = (event: { target: { value: string } }) => {
//         SetEmail(event.target.value);
//     };
//
//     const handlerPass = (e: { target: { value: string } }) => {
//         SetPass(e.target.value);
//     };
//
//     const fetchToLogin = (event: { preventDefault: () => void }) => {
//         event.preventDefault();
//
//         let option = {
//             method: "POST",
//             mode: "cors",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify({
//                 email: email,
//                 password: pass
//             })
//         };
//         if (checkEmail(email) && pass !== undefined) {
//             let customutl = apiUrlWithStore(`/api/customer/login?token=true`);
//             // @ts-ignore
//             fetch(customutl, option)
//                 .then((responce) => responce.json())
//                 .then((res) => {
//                     if (res.token && res.token !== "") {
//                         dispatch({type: "AUTHENTICATED", payload: true});
//                         dispatch({type: "CUSTOMER_TOKEN", payload: res.token});
//                         dispatch({type: "CUSTOMER_ID", payload: res.data.id});
//                         history.push("/account/profile/");
//
//                         fetch(
//                             `${url}/api/checkout/cart?token=${res.token}&api_token=${cartToken.cartToken}`
//                         )
//                             .then((responce) => responce.json())
//                             .then((resUser) => {
//
//                                 if (resUser) {
//                                     submitCartData(resUser, res.token);
//                                 }
//                             })
//                             .catch((err) => console.error(err));
//                     } else {
//                         // alert(res.error);
//                         //@ts-ignore
//                         toast.open(
//                             <span className="d-flex chek-fms">
//                                 <FailSvg/>
//                                 <FormattedMessage
//                                     id=""
//                                     defaultMessage={res.error}
//                                 />
//                             </span>
//                         )
//                     }
//                 });
//         } else {
//             //@ts-ignore
//             toast.open(
//                 <span className="d-flex chek-fms">
//                     <FailSvg/>
//                     <FormattedMessage
//                         id=""
//                         defaultMessage="Type valid data"
//                     />
//         </span>
//             )
//         }
//     };
//
//     const submitCartData = (products: { data: { items: any[]; } | null; }, token: any) => {
//         if (cart.items.length > 0 && products.data !== null) {
//             cart.items.map((product) => {
//                 // @ts-ignore
//                 let pro = products.data.items.find(
//                     // @ts-ignore
//                     (item) => item.product.id == product.product.id
//                 );
//                 if (pro == undefined) {
//                     fetch(
//                         // @ts-ignore
//                         `${url}/api/checkout/cart/add/${product.product.id}?product_id=${product.product.id
//                             // @ts-ignore
//                         }&quantity=${product.quantity}&api_token=${cartToken.cartToken}${token ? `&token=${token}` : ""
//                         }`,
//                         {method: "POST"}
//                     )
//                         .then((responce) => responce.json())
//                         .then((res) => console.log(res))
//                         .catch((err) => console.error(err));
//                 } else {
//                     // @ts-ignore
//                     cartRemoveItemAfterLogin(product.id, pro.product.id, dispatch);
//                 }
//             });
//         } else if (cart.items.length > 0 && products.data === null) {
//             cart.items.map((product) => {
//                 fetch(
//                     // @ts-ignore
//                     `${url}/api/checkout/cart/add/${product.product.id}?product_id=${product.product.id
//                         // @ts-ignore
//                     }&quantity=${product.quantity}&api_token=${cartToken.cartToken}${token ? `&token=${token}` : ""
//                     }`,
//                     {method: "POST"}
//                 )
//                     .then((responce) => responce.json())
//                     .then((res) => {
//                     })
//                     .catch((err) => console.error(err));
//             });
//         }
//
//         if (products.data !== null && products.data.items.length > 0) {
//             products.data.items.map((product) => {
//                 cartAddItemAfterLogin(
//                     product.product,
//                     [],
//                     product.quantity,
//                     cartToken,
//                     customer,
//                     selectedData,
//                     dispatch,
//                     products
//                 );
//             });
//         }
//     };
//
//     const checkEmail = (email: string | undefined): string => {
//         if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email as string)) {
//             return "true";
//         }
//         return "false";
//     };
//
//     const func = () => {
//         setOpen((open) => !open);
//     };
//
//     const dropdown = () => <IndicatoDropDownBody isOpened={open} setOpen={setOpen} func={func}/>
//     const iconSvg = () => <div><PersonFill/></div>
//
//     return (
//         <>
//             <Index
//                 // @ts-ignore
//                 url="/account/profile"
//                 className="account-logo-svg"
//                 func={func}
//                 openEd={open}
//                 account={"account"}
//                 dropdown={!authenticated ? dropdown() : ""}
//                 icon={iconSvg()}
//                 title={
//                     customer.authenticated == false ? (
//                         <FormattedMessage id="signIn" defaultMessage="Sign in"/>
//                     ) : (
//                         <FormattedMessage id="topbar.myAccount" defaultMessage="My account"/>
//                     )
//                 }
//             />
//         </>
//
//     );
// }
//
// export default IndicatorAccount;
